import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import queryString from "query-string";
import { Location } from "@reach/router";

import Edit from "../../components/guides/edit";
import { fetchGuides } from "../../actions";

import Layout from "../../components/layout";
import Container from "../../common/components/container";

const Index = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGuides());
  }, []);

  function returnForm(location, navigate) {
    const search = location.search ? queryString.parse(location.search) : {};
    return (
      <div>
        <Edit location={location} navigate={navigate} search={search} />
      </div>
    );
  }

  return (
    <Layout>
      <Container>
        <Location>
          {({ location, navigate }) => returnForm(location, navigate)}
        </Location>
      </Container>
    </Layout>
  );
};

export default Index;
